import axios from "axios";
import Store from "./Redux/Store";

 const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

const Axios = () => {
    const {support, env} = Store.getState();
    return axios.create({
        withCredentials : true,
        baseURL : env?.API_URL,
        headers : {
            "access-token" : getCookie('access-token'),
            ...support?.organisation_id ? {'support-organisation-id' : support?.organisation_id} : {},
            ...support?.user_id ? {'support-user-id' : support?.user_id} : {}
        }
    })
}

export default Axios;
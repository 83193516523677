import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../axios";
import {
  add_to_cart,
  get_cart_id,
  logout,
  redirect,
  req_type,
} from "../../utils/utils";
import * as $ from "jquery";
import ButtonWithSpinner from "../ButtonWithSpinner/ButtonWithSpinner";
import { update_page_data } from "../../Redux/reducers/pageSlice";
import { useNavigate } from "react-router";

const HostingRenewalModal = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const navigate = useNavigate();

  const plans = useSelector((state) =>
    state.plans.find((e) => e.id === data.plan_id)
  );
  const [select_duration, set_select_duration] = useState("1 YEARLY");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const update = (data) => dispatch(update_page_data(data));
  const { user, env } = useSelector((state) => state);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      cart_id: get_cart_id(),
      span: "YEARLY",
      duration: 1,
      meta: {
        domain_name: data.domain_name,
        order_type: "HOSTING_RENEWAL",
      },
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue("meta.domain_name", data.domain_name);
  }, [data]);

  const onsubmit = (values) => {
    setLoading(true);
    if (user?.data?.is_admin) {
      const d = {
        subscription_id: data.id,
        duration: values.duration,
        span: values.span,
      };
      Axios()
        .post(`${env?.API_URL}/v1/${req_type()}/hosting/renew`, d)
        .then((res) => {
          const s = res.data[0];
          if (s.status !== "success") {
            setError(s?.message);
            setTimeout(() => setError(null), 3500);
            setLoading(false);
            return;
          }
          $("#renewHostingModal").hide();
          $(".modal-backdrop").remove();
          update({ success_message: "Subscription renewed successfully" });
          setTimeout(() => update({ success_message: null }), 3000);
          props.getHostingList();
          setLoading(false);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            logout();
            navigate("/login");
            setLoading(false);
            return;
          } else {
            setError(
              err?.response?.data?.message || t("signup.errors.error_network")
            );
            setTimeout(() => setError(null), 2000);
            setLoading(false);
          }
        });
    } else {
      add_to_cart(values).then((res) => {
        redirect(`${env.CUSTOMER_URL}/cart`);
      });
    }
  };

  const values = watch();
  const handleDurationChange = (n) => {
    setValue("duration", parseInt(n.split(" ")[0]));
    setValue("span", n.split(" ")[1]);
    set_select_duration(n);
  };

  useEffect(() => {
    handleDurationChange("1 YEARLY");
  }, []);

  return (
    <div
      show={true}
      className="modal"
      id="renewHostingModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="renewHostingModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="renewHostingModalLabel">
              Renew Hosting
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="input-ctn">
                  <label>Duration</label>
                  <select
                    value={select_duration}
                    onChange={(e) => handleDurationChange(e.target.value)}
                    className="form-control "
                  >
                    {plans?.available_monthly &&
                      [1, 3, 6].map((count) => (
                        <option value={count + " MONTHLY"}>
                          {t("hosting_page.modal.form.month", { count })}
                        </option>
                      ))}
                    {[1, 2, 3].map((count) => (
                      <option value={count + " YEARLY"}>
                        {t("hosting_page.modal.form.year", { count })}
                      </option>
                    ))}
                  </select>
                </div>

                {plans && (
                  <p>
                    Total:{" "}
                    <span>
                      <b>
                        N
                        {plans[`naira_${values?.span?.toLowerCase() || 1}`] *
                          values.duration}
                      </b>
                    </span>
                  </p>
                )}
                <span className="badge badge-primary w-100 mt-2">
                  {error && (
                    <small id="sh-text7" className="form-text mt-0 text-left">
                      {error}
                    </small>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <ButtonWithSpinner
              loading={loading}
              classname="btn btn-success"
              onclick={handleSubmit((values) => onsubmit(values))}
              text="Renew Hosting"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostingRenewalModal;

import { Fragment, useEffect, useState } from 'react'
import * as $ from 'jquery'
import ClickAwayListener from 'react-click-away-listener';
import DomainStatus from '../../containers/DomainsPage/DomainItem/DomainStatus'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { update_page_data } from '../../Redux/reducers/pageSlice';

const HostingItem = (props) => {

    const {highestRank, data} = props;
    const {id , domain_name , plan , created_at , expiry, current_status, customer_name, customer_id, plan_id} = data;
    const [showOptions , setShowOptions] = useState(false);
    const {t} = useTranslation();
    const update = (data) => dispatch(update_page_data(data));

    const {user, plans} = useSelector(state => state);
    const [rank, setRank] = useState(1);

    const dispatch = useDispatch();

    useEffect(() => {
        plans && setRank(plans?.find(e => e.id == plan_id)?.rank);
    }, [plans])

    const openDropdown = () => {
        if (showOptions) return;
        $(`.action-dropdown.shadow.${id}`).fadeIn(100)        
        update({selected_subscription : data})
        setShowOptions(true)
    }

    const closeDropdown = () => {
        if (!showOptions) return;
        $(`.action-dropdown.shadow.${id}`).fadeOut(100);
        setShowOptions(false)
    }
    
    return (
        <tr>
            {user?.data?.is_super_admin && <td>{customer_name}</td>}
            <td>{domain_name}</td>
            <td>{plan}</td>
            <td>{created_at}</td>
            <td>{expiry}</td>
            <td><DomainStatus status = {current_status}/></td>
            <td>
                <div>
                    <div className="action-dropdown-ctn" onClick={() => openDropdown()} disabled = {showOptions}>
                      <i className="fas fa-ellipsis-h"></i>
                    </div>
                    <ClickAwayListener onClickAway={closeDropdown} >
                        <div className={`action-dropdown shadow ${id}`}>                            
                            {user?.data?.is_super_admin && <Link to={`/enterprise/customers/${customer_id}`} type='button' className="dropdown-item">{t('view_customer')}</Link>}
                            <Link to = {`/enterprise/hosting/${id}`}>Manage Hosting</Link>
                            {highestRank != rank && <a className="dropdown-item" data-toggle="modal" data-target="#upgradeModal" id="myBtn" href="#">Upgrade Plan</a>}
                            <a type='button' className="dropdown-item" data-toggle="modal" data-target="#renewHostingModal" id="myBtn">Renew</a>
                            {user?.data?.is_super_admin && <a type='button' className="dropdown-item" data-toggle="modal" data-target="#renewHostingModal" id="myBtn">Delete</a>}
                        </div>
                    </ClickAwayListener>
                </div>
            </td>
        </tr>
    )
}

export default HostingItem